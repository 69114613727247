import React from "react";

import Box from "../Box";
import Select from "../Select";
import { SearchWrapper } from "./filter.style";
import TEXT from "../../common/texts";

type FilterType = {
  value: string,
  label: string
}

type Props = {
  position: FilterType,
  experience: FilterType,
  telecomutting: FilterType,
  jobStatus: FilterType,
  employment: FilterType,
  onPositionChange: (value: string) => void,
  onExperienceChange: (value: string) => void,
  onTelecomuttingChange: (value: string) => void,
  onJobStatusChange: (value: string) => void,
  onEmploymentChange: (value: string) => void,
}

const FilterBar = (props: Props) => {
  const {
    positionList,
    position,
    experience,
    telecomutting,
    jobStatus,
    employment,
    onPositionChange,
    onExperienceChange,
    onTelecomuttingChange,
    onJobStatusChange,
    onEmploymentChange
  } = props;
  
  const positionOptions = React.useMemo(() => {
    const mapedOptions = positionList.map(position => ({ value: position, label: position }));
    return [{ value: null, label: '-- All positions --' }, ...mapedOptions];
  }, [positionList]);

  const employmentOptions = [
    { value: null, label: '-- All employment --' },
    { value: 'Full-time', label: 'Full-time' },
    { value: 'Contract', label: 'Contract' }
  ]

  const experienceOptions = [
    { value: null, label: '-- All experience --' },
    { value: 'Junior', label: 'Junior' },
    { value: 'Mid', label: 'Mid' },
    { value: 'Senior', label: 'Senior' }
  ]

  const telecomuttingOptions = [
    { value: null, label: '-- On-site and remote -- ' },
    { value: 'On-site', label: 'On-site' },
    { value: 'Remote', label: 'Remote' }
  ];

  const statusOptions = [
    { value: null, label: '-- Open and closed jobs -- ' },
    { value: 'Open', label: 'Open' },
    { value: 'Closed', label: 'Closed' },
    { value: 'On-hold', label: 'On-hold' }
  ]

  return (
    <Box>
      <SearchWrapper>
        <Select value={{ value: position, label: position || '-- All positions --' }} onChange={onPositionChange} options={positionOptions} placeholder={TEXT.POSITION} className="domain_search_select" aria-label="select options" />
        <Select value={{ value: experience, label: experience || '-- All experience --' }} onChange={onExperienceChange} options={experienceOptions} placeholder={TEXT.EXPERIENCE} className="domain_search_select" aria-label="select options" />
        <Select value={{ value: employment, label: employment || '-- All employment --' }} onChange={onEmploymentChange} options={employmentOptions} placeholder={TEXT.EMPLOYMENT} className="domain_search_select" aria-label="select options" />
        <Select value={{ value: telecomutting, label: telecomutting || '-- On-site and remote --'}} onChange={onTelecomuttingChange} options={telecomuttingOptions} placeholder={TEXT.TELECOMUTTING} className="domain_search_select" aria-label="select options" />
        <Select value={{ value: jobStatus, label: jobStatus || '-- Open and closed jobs -- ' }} onChange={onJobStatusChange} options={statusOptions} placeholder={TEXT.JOB_STATUS} className="domain_search_select" aria-label="select options" />
      </SearchWrapper>
    </Box>
  )
};
export default FilterBar;
