const TEXT = {
  REQUIRED_FIELD: 'This field is required.',
  ERROR_EMAIL_VALIDATION: 'Please enter a valid email address.',
  ERROR_LINKEDIN_VALIDATION: 'Please enter a valid public Linkedin profile URL.',
  ERROR_PHONE_VALIDATION: 'Please enter a valid phone number.',
  APPLICATION_SUBMITED_SUCCESS: 'Your application was succesfully submitted!',
  APPLICATION_SUBMITED_SUCCESS_MESSAGE: 'We will analyze your profile and contact you if we find that you are suitable for this job.',
  APPLICATION_SUBMITED_FAIL: 'Your application was not submitted.',
  APPLICATION_SUBMITED_ERROR: 'We encountered an error in submitting your application, please try again later.',
  LINKEDIN_FOLLOW: 'Follow us on LinkedIn for more IT job updates',
  APPLY_THROUGH_FORM: 'Apply for this position through this form',
  APPLY_THROUGH_FORM_DISCLAIMER: 'Resume or LinkedIn profile is required. Fields marked with * are mandatory.',
  FIRST_NAME_LABEL: 'First name *',
  FIRST_NAME_PLACEHOLDER: 'John',
  LAST_NAME_LABEL: 'Last name *',
  LAST_NAME_PLACEHOLDER: 'Smith',
  EMAIL_LABEL: 'E-mail address *',
  EMAIL_PLACEHOLDER: 'john@mail.com',
  PHONE_LABEL: 'Phone number',
  PHONE_PLACEHOLDER: '+40786272338',
  LINKEDIN_LABEL: 'Linkedin profile',
  LINKEDIN_PLACEHOLDER: 'https://www.linkedin.com/in/john',
  MESSAGE_LABEL: 'Anything we should know',
  DROP_RESUME: `Drag 'n' drop your resume here, or click to select`,
  SELECT_RESUME: 'Click to select your resume',
  INVALID_FILE: 'Invalid file format, please pick a document.',
  PRIVACY_POLICY: 'Privacy Policy',
  READ_AND_AGREED: 'I have read and agree to Talent Swipe',
  APPLY: 'APPLY',
  POSITION: 'Position',
  EXPERIENCE: 'Experience',
  EMPLOYMENT: 'Employment',
  TELECOMUTTING: 'On-site / Remote',
  JOB_STATUS: 'Available',
  SEARCH_PLACEHOLDER_MOBILE: 'Search jobs in IT ...',
  SEARCH_PLACEHOLDER_WEB: 'Search by technology... eg. JavaScript, Python, C#',
}

export default TEXT;