import React from "react"

const Video = ({ videoSrcURL, videoTitle, isSmallScreen, ...props }) => (
  <div className="video" style={{ marginTop: 10, width: '100%', height: '100%' }}>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      width="100%"
      height={isSmallScreen ? "100%" : "400px"}
    />
  </div>
)
export default Video;