import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import PropTypes from "prop-types";
import Icon from "react-icons-kit";
import { linkedinSquare } from "react-icons-kit/fa/linkedinSquare";
import { facebookSquare } from "react-icons-kit/fa/facebookSquare";
import { instagram } from "react-icons-kit/fa/instagram";

import { Box, Container, Heading, Logo, Text } from "@components/index";
import FooterWrapper, { List, ListItem } from "./footer.style";
import LogoImage from "../../../assets/image/logo.png";
import { DeviceContext } from "../../../contexts/DeviceProvider";

const Footer = ({ row, col, colOne, colTwo, titleStyle, logoStyle, textStyle, notMainPage }) => {
  const isSmallScreen = React.useContext(DeviceContext);

  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        Footer_Data {
          title
          menuItems {
            text
            url
            isExternal
          }
        }
      }
    }
  `);

  const transformItemUrl = React.useCallback((item) => {
    if (item.isExternal) {
      return (
        <a href={item.url} target="_blank" rel="noopener noreferrer" className="ListItem">
          {item.text}
        </a>
      );
    }

    if (!item.isExternal && !item.url.includes('#')) {
      return <Link to={`/${item.url}`}>{item.text}</Link>;
    }

    if (notMainPage && item.url.includes('#')) {
      return <Link to={`/${item.url}`}>{item.text}</Link>;
    }

    return (
      <AnchorLink href={item.url} className="ListItem">
        {item.text}
      </AnchorLink>
    );
  }, [notMainPage]);

  if (isSmallScreen) {
    return (
      <FooterWrapper>
        <Container className="footer_container">
          <Text content="© 2023 Talent Swipe. All rights reserved." {...textStyle} style={{ textAlign: "center", color: "gray", fontSize: "14px" }} />
          <Box className="row" {...row} style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <a href="https://www.linkedin.com/company/talentswipe" target="_blank" rel="noreferrer">
              <Icon icon={linkedinSquare} size={22} style={{ marginRight: 10, color: "gray" }} />
            </a>
            <a href="https://www.facebook.com/talentswipe.careers" target="_blank" rel="noreferrer">
              <Icon icon={facebookSquare} size={22} style={{ marginRight: 10, color: "gray" }} />
            </a>
            <a href="https://www.instagram.com/talentswipe.careers" target="_blank" rel="noreferrer">
              <Icon icon={instagram} size={22} style={{ color: "gray" }} />
            </a>
          </Box>
          <Box className="row" {...row} style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <a href="https://talentswipe.careers/privacy-policy">
              <Text content="Privacy Policy" {...textStyle} style={{ color: "gray", fontSize: "14px" }} />
            </a>
          </Box>
        </Container>
      </FooterWrapper>
    );
  }

  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo src={LogoImage} title="Talent Swipe" style={logoStyle} />
            <a href="mailto:contact@talentswipe.careers"> <Text content="contact@talentswipe.careers" {...textStyle} /></a>
            <a href="tel:+40764206586"><Text content="(+40) 764-206-586" {...textStyle} /></a>
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.dataJson.Footer_Data.map((widget, index) => (
              <Box className="col" {...col} key={`footer-widget-${index}`}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map((item, index) => (
                    <ListItem key={`footer-list-item-${index}`}>
                      {transformItemUrl(item)}
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  notMainPage: PropTypes.bool
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px"
  },
  // Footer col one style
  colOne: {
    width: [1, "35%", "35%", "23%"],
    mt: [0, "13px"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0]
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "65%", "65%", "77%"],
    flexBox: true,
    flexWrap: "wrap"
  },
  // Footer col default style
  col: {
    width: ["100%", "50%", "50%", "25%"],
    pl: "15px",
    pr: "15px",
    mb: "30px"
  },
  // widget title default style
  titleStyle: {
    color: "#343d48",
    fontSize: "16px",
    fontWeight: "700",
    mb: "30px"
  },
  // Default logo size
  logoStyle: {
    width: "100px",
    marginBottom: "15px"
  },
  // widget text default style
  textStyle: {
    color: "#0f2137",
    fontSize: "16px",
    mb: "10px"
  }
};

export default Footer;
