import styled from 'styled-components';

const LogoWrapper = styled.div`
  display: inline-block,
  mr: '1rem',
  a:hover,a:focus: {
    textDecoration: none
  }
`;

export { LogoWrapper };